import { AuthService } from './../../account/services/auth.service';
import { IProfile } from './../../_models/account/IUser';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private profileSource = new BehaviorSubject<IProfile>(null);
  profile$ = this.profileSource.asObservable();

  private roles = new BehaviorSubject<any>(null);
  roles$ = this.roles.asObservable();

  constructor(private authService: AuthService) {
  }

  changeProfile(profile: IProfile) {
    this.profileSource.next(profile);
  }

  getProfile() {

    this.authService.getProfile().subscribe(response => {
      if (response) {
        this.changeProfile(response);
      }
    });
  }
  // getCurrentUserRoles() {
  //   this.authService.getCurrentUserRoles().subscribe(response => {
  //     if (response) {
  //       response;
  //     }
  //   });
  // }
}
