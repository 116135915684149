import { SharedModule } from './shared/shared.module';
import { HostListener, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule } from '@angular/common/http';

export function tokenGetter() {
  return localStorage.getItem("token");
}
const hostname = window && window.location && window.location.hostname;

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:4200", "localhost:51214", "localhost:44366", "api-esm.gujarat.gov.in", "esm.gujarat.gov.in", "sepapi.orpgujarat.com", "esmapi.orpgujarat.com", "esm.orpgujarat.com", "sepdemo.orpgujarat.com"],
        //disallowedRoutes: [""],
      }
    }),
    SharedModule
  ],
  providers: [
    {
      //provide: 'API_BASE_URL', useValue: 'https://localhost:44366/'
     // provide: 'API_BASE_URL', useValue: 'https://esmapi.orpgujarat.com/'
      //provide: 'API_BASE_URL', useValue: 'https://api-esm.gujarat.gov.in/'

       provide: 'API_BASE_URL',
       useValue: (/^.*localhost.*/.test(hostname)) ? 'https://localhost:44366/' : (/^esm.orpgujarat.com/.test(hostname)) ? 'https://esmapi.orpgujarat.com/' : 'https://esmapi.orpgujarat.com/'
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  //@HostListener('document:mousemove', ['$event'])
  // onMouseMove(e) {
  //   console.log(e);
  // }
}
