import {
    house,
    envelopeFill,
    keyFill,
    telephoneFill,
    search,
    list,
    geoAltFill,
    chevronRight,
    briefcase,
    personFill,
    gear,
    power,
    plus,
    pencilSquare,
    hash,
    arrowUp,
    arrowDown,
    x,
    dash,
    building,
    geoFill,
    starFill,
    person,
    pencilFill,
    trashFill,
    checkCircle,
    exclamationCircle,
    fileEarmarkExcel,
    arrowClockwise
} from 'ngx-bootstrap-icons';

export const BOOLTSTRAP_ICONS = {
    house,
    envelopeFill,
    keyFill,
    telephoneFill,
    search,
    list,
    geoAltFill,
    chevronRight,
    briefcase,
    personFill,
    gear,
    power,
    plus,
    pencilSquare,
    hash,
    arrowUp,
    arrowDown,
    x,
    dash,
    building,
    geoFill,
    starFill,
    person,
    pencilFill,
    trashFill,
    checkCircle,
    exclamationCircle,
    fileEarmarkExcel,
    arrowClockwise
};