import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss']
})
export class SelectLanguageComponent implements OnInit {

  constructor(public translate: TranslateService , private router: Router,) { 
    //translate.currentLang = 'gu';

  }

  ngOnInit(): void {
  }

  filter(langSelect) {
   this.translate.use(langSelect);
   const currentUrl = this.router.url;
   //this.router.navigate([currentUrl]);
   setTimeout(() => {
    this.router.navigate([currentUrl])
    .then(() => {
      window.location.reload();
    });
  }, 500);
  
  }

}
