import { IRegistration } from './../../_models/account/IRegistration';
import { IConfirmRegistrationWithOtp, ILoginWithOTP, IRefreshToken, IRegistrationWithOtp, IResendOtp, ISetPassword } from './../../_models/account/IUser';
import { IChangePassword, IConfirmEmail, IForgotPassword, ILogin, IResetPassword } from '../../_models/account/IUser';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { catchError } from 'rxjs/operators';

import { ActivatedRoute, Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../_api/data.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class AuthService extends DataService {

  constructor(
    httpClient: HttpClient,
    private jwtHelper: JwtHelperService,
    private router: Router,
    @Inject('API_BASE_URL') private _baseUrl: string,
    toastrService: ToastrService,
    private route: ActivatedRoute) {
    super(_baseUrl + 'api/authorize', httpClient, toastrService);
  }

  login(login: ILogin) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/login', login)
      .pipe(catchError(this.handleError.bind(this)))
  }

  confirmEmail(confirmEmail: IConfirmEmail) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/confirm-email', confirmEmail)
      .pipe(catchError(this.handleError.bind(this)))
  }

  registrationWithOtp(emailLogin: IRegistrationWithOtp) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/registration-with-otp', emailLogin)
      .pipe(catchError(this.handleError.bind(this)))
  }

  confirmRegistrationWithOtp(confirmEmailLogin: IConfirmRegistrationWithOtp) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/confirm-registration-with-otp', confirmEmailLogin)
      .pipe(catchError(this.handleError.bind(this)))
  }

  createNewPassword(confirmEmailLogin: ISetPassword) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/createpassword', confirmEmailLogin)
      .pipe(catchError(this.handleError.bind(this)))
  }
  confirmresetwithotp(confirmEmailLogin: IConfirmRegistrationWithOtp) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/reset-with-otp', confirmEmailLogin)
      .pipe(catchError(this.handleError.bind(this)))
  }
  forgotPassword(forgotPassword: IForgotPassword) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/forgot-password', forgotPassword)
      .pipe(catchError(this.handleError.bind(this)))
  }

  resetPassword(resetPassword: IResetPassword) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/reset-password', resetPassword)
      .pipe(catchError(this.handleError.bind(this)))
  }

  registration(resource: ISetPassword) {
    return this.httpClient.post(this._baseUrl + 'api/authorize/registration', resource)
      .pipe(catchError(this.handleError.bind(this)))
  }

  getProfile() {
    return this.httpClient.get(this._baseUrl + 'api/authorize/profile')
      .pipe(catchError(this.handleError.bind(this)))
  }

  changePassword(changePassword: IChangePassword) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/change-password', changePassword)
      .pipe(catchError(this.handleError.bind(this)))
  }
  refreshToken(refreshToken: IRefreshToken) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/refresh-token', refreshToken)
      .pipe(catchError(this.handleError.bind(this)))
  }
  loginWithOtp(confirmEmailLogin: ILoginWithOTP) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/login-with-otp', confirmEmailLogin)
      .pipe(catchError(this.handleError.bind(this)))
  }
  resendOtp(emailOrPhone: IResendOtp) {
    return this.httpClient.post(this._baseUrl + 'api/authentication/resend-otp', emailOrPhone)
    .pipe(catchError(this.handleError.bind(this)))
  }
  logout() {
    if (typeof localStorage !== "undefined")
      localStorage.clear();

    //this.router.navigate(['/account/login']);
    this.router.navigate(['/home']);
  }

  isLoggedIn() {
    if (this.getToken && this.jwtHelper.isTokenExpired()) {
      localStorage.clear();
      this.router.navigate(['/account/login']);
    }

    return !this.jwtHelper.isTokenExpired();
  }

  getCurrentUserRoles(isUser: any) {
    return this.httpClient.get(this._baseUrl + 'api/authorize/get-current-user-roles?isUser=' + isUser)
      .pipe(catchError(this.handleError.bind(this)))
  }


  get currentUser() {
    let token = (typeof localStorage !== "undefined") ? localStorage.getItem('token') : null;
    if (!token)
      return null;
    return this.jwtHelper.decodeToken(token);
  }

  get getToken() {
    let token = (typeof localStorage !== "undefined") ? localStorage.getItem('token') : null;
    if (!token)
      return null;
    return token;
  }

  get currentUserId() {
    let token = (typeof localStorage !== "undefined") ? localStorage.getItem('token') : null;
    if (!token)
      return null;
    return this.jwtHelper.decodeToken(token)['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
  }

  isInRole(role: string) {
    return role === this.currentSelectedRole;

    // let roles = this.currentUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    // if (roles) {
    //   if (typeof roles == 'object' && roles.filter((x: string) => x == role) == role) {
    //     return true;
    //   }
    //   else if (typeof roles == 'string' && roles == role) {
    //     return true;
    //   }
    // }
    // return false;
  }

  currentUserRoles() {
    let roles = this.currentUser['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

    if (typeof roles === 'string') {
      return [roles];
    }

    return roles;
  }

  loginUserId() {
    let currentUserId = this.currentUserId;
    return currentUserId;
  }

  redirectToDashboard() {
    let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    let query = returnUrl ? this.QueryStringToJSON(returnUrl) : {};
    let url = returnUrl && returnUrl.split('?').length >= 2 ? returnUrl.split('?')[0] : returnUrl;
    const currentSelectedRole = this.currentSelectedRole;

    if (currentSelectedRole == "user") {
      this.router.navigate([url || '/ex-sainik-service'], { queryParams: query });
    }
    else if (currentSelectedRole == "oszsb" || currentSelectedRole == "dswrozsb" || currentSelectedRole == "adswro"
      || currentSelectedRole == "lwozsb" || currentSelectedRole == "regnclerkzsb" || currentSelectedRole == "wozsb" || currentSelectedRole == "headclerk"
      || currentSelectedRole == "seniorclerk" || currentSelectedRole == "hs" || currentSelectedRole == "clerk1"
      || currentSelectedRole == "clerk2" || currentSelectedRole == "clerk3" || currentSelectedRole == "clerk4" || currentSelectedRole == "clerk5" ||
      currentSelectedRole == "clerk6" || currentSelectedRole == "clerk7" || currentSelectedRole == "clerk8" || currentSelectedRole == "clerk9" ||
      currentSelectedRole == "clerk10" || currentSelectedRole == "clerk11" || currentSelectedRole == "osrsb"
      || currentSelectedRole == "stenographerrsb" || currentSelectedRole == "clerk1rsb" || currentSelectedRole == "clerk2rsb" || currentSelectedRole == "clerk3rsb" || currentSelectedRole == "clerk4rsb"
      || currentSelectedRole == "clerk5rsb" || currentSelectedRole == "clerk6rsb" || currentSelectedRole == "clerk7rsb" || currentSelectedRole == "clerk8rsb" || currentSelectedRole == "clerk9rsb" || currentSelectedRole == "clerk10rsb"
      || currentSelectedRole == "clerk11rsb") {
      this.router.navigate([url || '/district'], { queryParams: query });
    }
    else if (currentSelectedRole == "state" || currentSelectedRole == "deptdirectorrsb" || currentSelectedRole == "directorrsb") {
      this.router.navigate([url || '/state'], { queryParams: query });
    } else if (currentSelectedRole == "admin") {
      this.router.navigate([url || '/admin/all-user'], { queryParams: query });
    }
  }

  switchRole(role: string) {
    localStorage.setItem('currentSelectedRole', role);
  }

  get currentSelectedRole(): string {
    let role = '';
    if (typeof localStorage !== "undefined")
      role = localStorage.getItem('currentSelectedRole');

    return role;
  }


  QueryStringToJSON(query) {
    query = query.split('?').length >= 2 ? query.split('?')[1] : "";
    if (query) {
      let pairs = query.split('&');

      var result = {};
      pairs.forEach((pair) => {
        let pair_t = pair.split('=');
        result[pair_t[0]] = decodeURIComponent(pair_t[1] || '');
      });

      return JSON.parse(JSON.stringify(result));
    }
    else {
      return {};
    }
  }
  redirectBackURL(urlname) {
    var userPath = "";
    if (this.isLoggedIn() && this.isInRole("user")) {
      userPath = 'jobseeker';
    }
    else if (this.isLoggedIn() && (this.isInRole('clerk1') || this.isInRole('clerk2') || this.isInRole('clerk3') || this.isInRole('clerk1rsb') || this.isInRole('clerk2rsb') || this.isInRole('clerk3rsb')
      || this.isInRole('clerk4rsb') || this.isInRole('clerk5rsb') || this.isInRole('clerk6rsb') || this.isInRole('clerk7rsb')
      || this.isInRole('clerk8rsb') || this.isInRole('clerk9rsb') || this.isInRole('clerk10rsb') || this.isInRole('clerk11rsb')
      || this.isInRole('wozsb') || this.isInRole('lwozsb') || this.isInRole('hs') || this.isInRole('oszsb')
      || this.isInRole('headclerk') || this.isInRole('seniorclerk') || this.isInRole('dswrozsb') || this.isInRole('adswro') || this.isInRole('osrsb') || this.isInRole('stenographerrsb'))) {
      userPath = 'district';
    }
    else if (this.isLoggedIn() && (this.isInRole('clerk1rsb') || this.isInRole('clerk2rsb') || this.isInRole('clerk3rsb')
      || this.isInRole('clerk4rsb') || this.isInRole('clerk5rsb') || this.isInRole('clerk6rsb') || this.isInRole('clerk7rsb')
      || this.isInRole('clerk8rsb') || this.isInRole('clerk9rsb') || this.isInRole('clerk10rsb') || this.isInRole('clerk11rsb')
      || this.isInRole('osrsb') || this.isInRole('stenographerrsb')) || this.isInRole('directorrsb') || this.isInRole('deptdirectorrsb') || this.isInRole('sceniorclerkrsb') || this.isInRole('headclerkrsb')) {
      userPath = 'state';
    }
    else if (this.isLoggedIn() && this.isInRole("admin")) {
      userPath = 'admin';
    }
    return '/' + userPath + '/' + urlname;
  }
}
