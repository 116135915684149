import { IProfile, IRefreshToken } from './../../../_models/account/IUser';
import { ProfileService } from './../../../_api/services/profile.service';
import { AuthService } from './../../../account/services/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-bar',
  templateUrl: './user-bar.component.html',
  styleUrls: ['./user-bar.component.css']
})
export class UserBarComponent implements OnInit {
  profile$: Observable<IProfile>;
  @Input() class: string = "";

  email: string = '';
  currentUserRoles: any[];
  currentUserId: any;
  userRoles: any;

  loading: boolean;
  currentUserForm: FormGroup;
  refreshToken: IRefreshToken;
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private fb: FormBuilder
  ) {
    this.email = this.authService.currentUser.sub;
  }

  ngOnInit(): void {
    this.profile$ = this.profileService.profile$;
    this.profileService.getProfile();
    this.createLoginForm();
    this.currentUserId = this.authService.loginUserId();
    var currentRole = this.authService.currentUserRoles();
    this.authService.getCurrentUserRoles(currentRole).subscribe((response: any) => {
      if (response) {
        this.userRoles = response;
      }
    },
      (error) => console.log('HTTP Error', error),
      () => this.loading = false
    );
  }

  createLoginForm() {
    this.currentUserForm = this.fb.group({
      userId: [''],
      roleName: [''],
      districUserManagementId: [''],
    });
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  // get userRoles() {
  //   this.currentUserId = this.authService.loginUserId();
  //   return this.authService.currentUserRoles();
  // }

  switchRole(role, districUserManagementId) {
    this.currentUserForm.patchValue({
      userId: this.currentUserId,
      roleName: role,
      districUserManagementId: districUserManagementId,
    });
    this.refreshToken = Object.assign({}, this.currentUserForm.value);
    this.authService.refreshToken(this.refreshToken).subscribe((response: any) => {
      if (response) {
        localStorage.setItem('token', response.tokenString);
        this.authService.switchRole(role);
        this.authService.redirectToDashboard();
        window.location.reload();
      }
    },
      (error) => console.log('HTTP Error', error),
      () => this.loading = false
    );

  }

  logout() {
    this.authService.logout();
  }

  tokenRefresh(evnet: any) {
    this.currentUserForm.patchValue({
      userId: this.currentUserId,
      roleName: evnet,
    });
    this.refreshToken = Object.assign({}, this.currentUserForm.value);
    this.authService.refreshToken(this.refreshToken).subscribe((response: any) => {
      if (response) {
        localStorage.setItem('token', response.tokenString);
        let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
        let query = returnUrl ? this.QueryStringToJSON(returnUrl) : {};
        let url = returnUrl && returnUrl.split('?').length >= 2 ? returnUrl.split('?')[0] : returnUrl;
        if (response.role == "user") {
          this.router.navigate([url || '/ex-sainik-service'], { queryParams: query });
        }
        else if (response.role == "district") {
          this.router.navigate([url || '/district'], { queryParams: query });
        }
        else if (response.role == "regional") {
          this.router.navigate([url || '/regional'], { queryParams: query });
        }
        else if (response.role == "state") {
          this.router.navigate([url || '/state'], { queryParams: query });
        }
        else if (response.role == "deptdirectorrsb") {
          this.router.navigate([url || '/state'], { queryParams: query });
        }
        else if (response.role == "directorrsb") {
          this.router.navigate([url || '/state'], { queryParams: query });
        }
        else if (response.role == "oszsb") {
          this.router.navigate([url || '/district'], { queryParams: query });
        }
        else if (response.role == "dswrozsb") {
          this.router.navigate([url || '/district'], { queryParams: query });
        }
        else if (response.role == "adswro") {
          this.router.navigate([url || '/district'], { queryParams: query });
        }
        else {
          this.router.navigate([url || '/'], { queryParams: query });
        }

      }
    },
      (error) => console.log('HTTP Error', error),
      () => this.loading = false
    );
  }
  QueryStringToJSON(query) {
    query = query.split('?').length >= 2 ? query.split('?')[1] : "";
    if (query) {
      let pairs = query.split('&');

      var result = {};
      pairs.forEach((pair) => {
        let pair_t = pair.split('=');
        result[pair_t[0]] = decodeURIComponent(pair_t[1] || '');
      });

      return JSON.parse(JSON.stringify(result));
    }
    else {
      return {};
    }
  }
}
