import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.css']
})
export class FooterBarComponent implements OnInit {
  logos: any[] = [
    { img: '/assets/img/footerslide/digital-india.png', alt: 'digital-india', link: '#' },
    { img: '/assets/img/footerslide/eci_0.png', alt: 'eci_0', link: '' },
    { img: '/assets/img/footerslide/e-gazette.png', alt: 'e-gazette', link: '#' },
    { img: '/assets/img/footerslide/GoI-directory.png', alt: 'GoI-directory', link: '#' },
     { img: '/assets/img/footerslide/india-gov.png', alt: 'india-gov', link: '#' },
     { img: '/assets/img/footerslide/mygov.png', alt: 'mygov', link: '#' },
     { img: '/assets/img/footerslide/pmnrf.png', alt: 'pmnrf', link: '#' }
  ];

  slideConfig = {
    "lazyLoad": 'ondemand',
    "slidesToShow": 5,
    "infinite": true,
    "slidesToScroll": 1,
    "arrows": false,
    "dots": false,
    "autoplay": true,
    "autoplaySpeed": 2000,
    "responsive": [
      {
        "breakpoint": 1024,
        "settings": {
          "slidesToShow": 6,
          "slidesToScroll": 3,
          "infinite": true,
          "dots": true
        }
      },
      {
        "breakpoint": 600,
        "settings": {
          "slidesToShow": 4,
          "slidesToScroll": 2
        }
      },
      {
        "breakpoint": 480,
        "settings": {
          "slidesToShow": 2,
          "slidesToScroll": 1
        }
      }
    ]
  };

  constructor() { }

  ngOnInit(): void {
  }

}
