import { AuthService } from 'src/app/account/services/auth.service';
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { LoaderService } from './shared/services/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  time: any;
  myValue: string;
  myValueSub: Subscription;

  pageReloaded: any;
  tabWasClosed: boolean = false;
  // @HostListener('window:unload', ['$event'])
  // unloadHandler($event) {
  //   alert('call unload');
  //   console.log($event)
  //   // let tabCount = parseInt(localStorage.getItem('tabCount'));
  //   // if (tabCount == 0) {
  //   //   $event.returnValue = 'You have closed the browser. Do you want to logout from your application?';
  //   //   this.authService.logout();
  //   // }
  //   if (this.tabWasClosed) {
  //     this.authService.logout();
  //   }
  // }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHandler($event) {
  //   alert('call beforeunload');
  //   console.log($event)

  //   // if (!this.pageReloaded && document.visibilityState) {
  //   //   let tabCount = parseInt(localStorage.getItem('tabCount'));
  //   //   --tabCount;
  //   //   localStorage.setItem('tabCount', tabCount.toString());
  //   // }
  //   if (document.visibilityState === 'hidden') {
  //     this.tabWasClosed = true;
  //   }

  // }
  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private authService: AuthService
  ) {
    this.router.events.subscribe((event: any) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loaderService.show();
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loaderService.hide();
          break;
        }
        default: {
          break;
        }

      }
    });
    // this.pageReloaded = window.performance
    //   .getEntriesByType('navigation')
    //   .map((nav) => (nav as any).type)
    //   .includes('reload');
    // // We need to parse into integer since local storage can only
    // // store strings.
    // let tabCount = parseInt(localStorage.getItem("windowCount"));
    // // Then we instantiate tabCount if it doesn't already exist
    // // OR Increment by 1 if it already exists
    // tabCount = Number.isNaN(tabCount) ? 1 : ++tabCount;
    // // Set the count on local storage
    // localStorage.setItem('tabCount', tabCount.toString());

  }

  ngOnInit(): void {
    //this.resetTimer();

    this.authService.redirectToDashboard();

  }
  // @HostListener('document:mousemove')
  // @HostListener('document:keypress')
  // @HostListener('document:click')
  // @HostListener('document:wheel')
  // resetTimer() {
  //   clearTimeout(this.time);
  //   this.time = setTimeout(() => {
  //     alert('Idle for 3 seconds. You can call your api here');
  //   }, 3000);
  // }
}
