import { LoaderService } from './loader.service';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptorService implements HttpInterceptor {
  constructor(private loaderService: LoaderService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    if (req.method === 'GET') {
      return next.handle(req);
    }
    if (req.method === 'POST') {
      return next.handle(req);
    }
    if (req.method === 'PUT') {
      return next.handle(req);
    }
    if (req.method === 'DELETE') {
      return next.handle(req);
    }

    this.showLoader();
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.hideLoader();
      }
    },
      (err: any) => {
        this.hideLoader();
      }));
  }
  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }
}