import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NoAccessComponent } from './shared/components/no-access/no-access.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  //{ path: '', redirectTo: 'account/app-offline', pathMatch: 'full' },
  {
    path: 'home',
    data: { breadcrumb: 'Home' },
    loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
    //redirectTo: 'account/app-offline'
  },
  {
    path: 'account',
    data: { breadcrumb: 'Account' },
    loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule)
  },
  {
    path: 'contact-us',
    data: { breadcrumb: 'Contact Us' },
    loadChildren: () => import('./contact/contact.module').then(mod => mod.ContactModule)
  },
  {
    path: 'admin',
    data: { breadcrumb: 'Admin' },
    loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule)
  },
  {
    path: 'jobseeker',
    data: { breadcrumb: 'Job Seeker' },
    loadChildren: () => import('./job-seeker/job-seeker.module').then(m => m.JobSeekerModule)
  },
  {
    path: 'district',
    data: { breadcrumb: 'District' },
    loadChildren: () => import('./district/district.module').then(m => m.DistrictModule)
  },
  {
    path: 'state',
    data: { breadcrumb: 'State' },
    loadChildren: () => import('./state/state.module').then(m => m.StateModule)
  },
  {
    path: 'profile',
    data: { breadcrumb: 'Profile' },
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'ex-sainik-service',
    data: { breadcrumb: 'ESM Sainik Service' },
    loadChildren: () => import('./ex-sainik-service/ex-sainik-service.module').then(m => m.ExSainikServiceModule)
  },
  {
    path: 'registration-staps',
    data: { breadcrumb: 'Registration Staps' },
    loadChildren: () => import('./registration-staps/registration-staps.module').then(m => m.RegistrationStapsModule)
  },
  {
    path: 'download',
    data: { breadcrumb: 'Notificatons' },
    loadChildren: () => import('./notice-board/notice-board.module').then(m => m.NoticeBoardModule)
  },
  {
    path: 'page',
    data: { breadcrumb: 'Page' },
    loadChildren: () => import('./page/page.module').then(m => m.PageModule)
    // loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
    data: { breadcrumb: "No Access" }
  },
  {
    path: '404',
    component: NotFoundComponent,
    data: { breadcrumb: "404 Error" }
  },

  {
    path: '**',
    redirectTo: '/404',
    data: { breadcrumb: "404 Error" }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
