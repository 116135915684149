import { environment } from './../../environments/environment';

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';



@Injectable({
  providedIn: "root"
})
export class DataService {
  
   
  httpClient: HttpClient;

  constructor(
    
    
    @Inject('API_BASE_URL') 
  private url: string, @Inject(HttpClient) httpClient: HttpClient, 
  @Inject(ToastrService) 
  private toastrService: ToastrService,
  
  ) {
    this.httpClient = httpClient;
  }

  getList(resource: any) {
    return this.httpClient.post(this.url + '/list?', resource)
      .pipe(catchError(this.handleError.bind(this)))
  }
  
  getAll(filter: any) {
    return this.httpClient.get(this.url + '?' + this.toQueryString(filter))
      .pipe(catchError(this.handleError.bind(this)))
  }

  get(id: any) {
    return this.httpClient.get(this.url + '/' + id)
      .pipe(catchError(this.handleError.bind(this)))
  }
 
  
  create(resource: any) {
    return this.httpClient.post(this.url, resource)
      .pipe(catchError(this.handleError.bind(this)))
  }

  update(id: any, resource: any) {
    return this.httpClient.put(this.url + '/' + id, resource)
      .pipe(catchError(this.handleError.bind(this)))
  }

  delete(id: any) {
    return this.httpClient.delete(this.url + '/' + id)
      .pipe(catchError(this.handleError.bind(this)))
  }

  toQueryString(obj: any) {
    var parts = [];
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined)
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
    }

    return parts.join('&');
  }

  extractData(res: Response) {
    let body = res;
    return body || {};
  }

  error(error: any) {
    if(!environment.production)
      this.toastrService.error(error);
  }

  badInput(error?: any) {
    let modelStateErrors = '';
    if (error?.errors) {
      for (const key in error.errors) {
        if (error.errors[key]) {
          modelStateErrors += error.errors[key] + '\n';
        }
      }
    }
    else {
      for (const key in error) {
        if (error[key]) {
          modelStateErrors += error[key] + '\n';
        }
      }
    }
    this.toastrService.error(modelStateErrors);
  }

  forbiddenError(errorResponse) {
    localStorage.removeItem("token");
    if(errorResponse?.error?.content)
    {
      this.toastrService.error(errorResponse?.error?.content);
    }else
    {
      this.toastrService.error('unauthorized access.');      
    }
    window.location.href='/';
  }

  notFoundError() {
    this.toastrService.error('not found.');
  }

  handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.status == 400)
      throwError(this.badInput(errorResponse.error));
    else if (errorResponse.status === 404)
      throwError(this.notFoundError());
    else if (errorResponse.status == 401)
      throwError(this.forbiddenError(errorResponse));
    else
      throwError(this.error(errorResponse));

    return EMPTY;
  }
}