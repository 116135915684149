import {
    Component,
    Input,
    Output,
    EventEmitter
  } from '@angular/core';
  import { OnChanges } from '@angular/core';
  
  @Component({
    selector: 'paginate',
    template: `
    <nav *ngIf="totalItems > pageSize">
        <ul class="pagination justify-content-center">
            <li class="page-item" [class.disabled]="currentPage == 1">
                <a href="javascript:;" (click)="previous()" aria-label="Previous" class="page-link">
                <span aria-hidden="true">&laquo;</span>
                </a>
            </li>
            <li class="page-item" [class.active]="currentPage == page" *ngFor="let page of pages" (click)="changePage(page)">
                <a href="javascript:;"  class="page-link">{{ page }}</a>
            </li>
            <li class="page-item" [class.disabled]="currentPage == pages.length">
                <a href="javascript:;"  (click)="next()" aria-label="Next" class="page-link">
                <span aria-hidden="true">&raquo;</span>
                </a>
            </li>
        </ul>
    </nav>  
  `
  })
  export class PaginateComponent implements OnChanges {
    @Input() totalItems;
    @Input() pageSize = 10;
    @Output() pageChanged = new EventEmitter();
    pages: any[];
    currentPage = 1;

	firstPage: number = 1;
	lastPage: number;
	nextPage: number;
	prevPage: number;

	linkCount: number = 10;
    constructor() { }
    ngOnChanges() {
      this.currentPage = 1;
  
      var pagesCount = Math.ceil(this.totalItems / this.pageSize);
      this.lastPage = Number(pagesCount);
      var last = Number(this.currentPage) + (this.linkCount / 2);
      var first = last - this.linkCount + 1;
      if (last > this.lastPage) {
          first -= last - this.lastPage;
          last = this.lastPage;
      }
      if (first <= 0) {
          last = Math.min(last + (0 - first), this.lastPage);
          first = 1;
      }

      this.pages = [];
      for (var i = first; i <= last; i++)
			this.pages.push(i);

    }
  
    changePage(page) {
      this.currentPage = page;
      this.pageChanged.emit(page);
    }
  
    previous() {
      if (this.currentPage == 1)
        return;
  
      this.currentPage--;
      this.pageChanged.emit(this.currentPage);
    }
  
    next() {
      if (this.currentPage == this.pages.length)
        return;
  
      this.currentPage++;
      this.pageChanged.emit(this.currentPage);
    }
  }